import { max, min } from "d3-array";
import { scaleTime, scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import { line, curveMonotoneX } from "d3-shape";
import React, { useEffect, useRef } from "react";

const [width, height] = [187, 94];
const margin = { top: 10, right: 10, bottom: 10, left: 10 };

function D3Sparkline({ data, color }) {
  const refs = useRef();

  useEffect(() => {
    const T = data.length;

    const chartRight = width - margin.right;
    const chartBottom = height - margin.bottom;

    const xScale = scaleTime()
      .clamp(true)
      .domain(T ? [new Date(data[0][0]), new Date(data[T - 1][0])] : [])
      .range([margin.left, chartRight]);

    const svg = select(refs.current);

    const dMax = max(data, (date) => date[1]);
    const dMin = min(data, (date) => date[1]);

    const yScale = scaleLinear()
      .clamp(true)
      .domain([dMin, dMax])
      .range([chartBottom, margin.top]);

    var curveFunc = line()
      .curve(curveMonotoneX) // This is where you define the type of curve. Try curveStep for instance.
      .x(function (d) {
        return xScale(d[0]);
      })
      .y(function (d) {
        return yScale(d[1]);
      });
    let pathLength;
    svg
      .selectAll("path")
      .data([data])
      .join((enter) =>
        enter
          .append("path")
          .attr("fill", "none")
          .attr("stroke", `${color}`)
          .attr("stroke-width", 4)
          .attr("d", curveFunc)
          .attr("stroke-dasharray", function () {
            return (pathLength = this.getTotalLength());
          })
          .call((enter) =>
            enter
              .attr("stroke-dashoffset", pathLength)
              .transition()
              .delay(100)
              .duration(2500)
              .attr("stroke-dashoffset", 0)
          )
      );
  }, [data, color]);

  return (
    <div className="aspect-w-4 aspect-h-2">
      <svg
        ref={(el) => {
          refs.current = el;
        }}
        width="100%"
        height="100%"
        viewBox={`0 0 187 94`}
        preserveAspectRatio="xMidYMid meet"
      />
    </div>
  );
}

export default React.memo(D3Sparkline, () => true);
