import { Transition } from "@windmill/react-ui";
import React, { useState } from "react";

export default function DropdownMenu({ current = 0, menus, onChange, icon }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const currentMenu = menus[current] || menus[menus.list[0]];
  const onMenuClick = (id) => {
    setIsOpen(false);
    onChange(id);
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="cursor-pointer" onClick={toggle}>
        {icon} {currentMenu.text}{" "}
        <svg
          className="-mr-1 ml-2 h-5 w-5 inline-block"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-10 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          style={{zIndex: 999999999999999999999999999999999999}}
          onMouseLeave={() => setIsOpen(false)}
          className="absolute left-0 w-56 mt-2 rounded shadow bg-white cursor-pointer max-h-72 overflow-auto"
        >
          {menus.list.map((mkey, i) => {
            const m = menus[mkey];
            return (
              <div
                key={m.id}
                onClick={() => onMenuClick(m.id)}
                className="block px-4 py-2 text-sm font-normal leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                {m.text}
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}
