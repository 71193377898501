import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ChartConfirmed = ({ data, name = "Лүоъ", height }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const confirmed = {
      name: "Батлагдсан",
      data: [],
      type: "line",
    };
    const recovered = {
      name: "Эдгэрсэн",
      data: [],
      type: "line",
    };

    data.forEach((r) => {
      confirmed.data.push([r.ct, r.tc]);
      recovered.data.push([r.ct, r.tr]);
    });

    setSeries([confirmed, recovered]);
  }, [data]);

  useEffect(() => {
    return () => {
      setSeries([]);
    };
  }, []);

  const options = {
    colors: ["rgba(255,7,58)", "#28a745"],
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        top: -20,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    markers: {
      size: 0,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "yyyy/MM",
          day: "MM/dd",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {
      x: {
        format: "yy/MM/dd",
      },
    },
    annotations: {
      xaxis: [
        {
          x: new Date("9 Nov 2020").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "Дотоод халдвар: 0",
          },
        },
        {
          x: new Date("21 May 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "50k",
          },
        },
        {
          x: new Date("24 Jun 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "100k",
          },
        },
        {
          x: new Date("22 Jul 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "150k",
          },
        },
        {
          x: new Date("21 Aug 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "200k",
          },
        },
        {
          x: new Date("11 Sep 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "250k",
          },
        },
        {
          x: new Date("29 Sep 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "300k",
          },
        },
        {
          x: new Date("25 Oct 2021").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "350k",
          },
        },
        {
          x: new Date("13 Jan 2022").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0aa",
          label: {
            borderColor: "#775DD0aa",
            style: {
              color: "#fff",
              background: "#775DD0aa",
            },
            text: "400k",
          },
        },
        {
          x: new Date("12 Nov 2020").getTime(),
          x2: new Date("14 Dec 2020").getTime(),
          strokeDashArray: 0,
          fillColor: "#bc5090",
          opacity: 0.25,
          borderColor: "#bc5090",
          label: {
            offsetX: 17,
            offsetY: 10,
            textAnchor: "middle",
            borderColor: "#bc5090dd",
            style: {
              color: "#fff",
              background: "#bc5090dd",
            },
            text: "X.Хорио: Улс даяар",
          },
        },

        {
          x: new Date("23 Dec 2020").getTime(),
          x2: new Date("11 Jan 2021").getTime(),
          fillColor: "#ef5675",
          opacity: 0.25,
          strokeDashArray: 0,
          borderColor: "#ef5675dd",
          label: {
            offsetY: 10,
            offsetX: 17,
            textAnchor: "middle",
            borderColor: "#ef5675dd",
            style: {
              color: "#fff",
              background: "#ef5675dd",
            },
            text: "X.Хорио: Улаанбаатар",
          },
        },
        {
          x: new Date("11 Feb 2021").getTime(),
          x2: new Date("23 Feb 2021").getTime(),
          strokeDashArray: 0,
          fillColor: "#bc5090",
          opacity: 0.25,
          borderColor: "#bc5090",
          label: {
            offsetX: 17,
            offsetY: 10,
            textAnchor: "middle",
            borderColor: "#bc5090dd",
            style: {
              color: "#fff",
              background: "#bc5090dd",
            },
            text: "Xөл хорио #3",
          },
        },
        {
          x: new Date("10 Apr 2021").getTime(),
          x2: new Date("08 May 2021").getTime(),
          strokeDashArray: 0,
          fillColor: "#bc5090",
          opacity: 0.25,
          borderColor: "#bc5090",
          label: {
            offsetX: 17,
            offsetY: 10,
            textAnchor: "middle",
            borderColor: "#bc5090dd",
            style: {
              color: "#fff",
              background: "#bc5090dd",
            },
            text: "Xөл хорио #4",
          },
        },
        {
          x: new Date("24 May 2021").getTime(),
          x2: new Date("08 Jun 2021").getTime(),
          strokeDashArray: 0,
          fillColor: "#059669",
          opacity: 0.25,
          borderColor: "#059669",
          label: {
            offsetX: 17,
            offsetY: 10,
            textAnchor: "middle",
            borderColor: "#059669dd",
            style: {
              color: "#fff",
              background: "#059669dd",
            },
            text: "Сонгуулийн сурталчилгаа",
          },
        },
      ],
    },
    legend: {
      height: 30,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 320,
          },
        },
      },
    ],
  };

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height={height || "auto"}
    />
  );
};

export default ChartConfirmed;
