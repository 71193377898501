import { memo } from "react";
import D3Sparkline from "./D3Sparkline";

const RecoveredCard = ({ updated, data }) => {
  return (
    <div className="shadow-sm rounded bg-gradient-to-br from-white via-green-100 to-green-200 pt-4">
      <div className="font-sans font-bold text-sm text-center text-green-700 pb-4">
        Эдгэрсэн
      </div>
      <div
        className="font-sans font-bold text-xl text-center text-green-500"
        data-tip={updated}
      >
        &nbsp;+{data.new}
      </div>
      <div className="font-sans font-bold text-2xl md:text-2xl text-center text-green-700">
        {data.ttl.toLocaleString()}
      </div>
      <D3Sparkline data={data.trend || []} color="rgba(4, 120, 87, 0.8)" />
    </div>
  );
};

export default memo(RecoveredCard, () => true);
