import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Health from "./pages/Health";

const Police = lazy(() => import("./pages/Police"));
const Government = lazy(() => import("./pages/Government"));
const EmbedRoute = lazy(() => import("./pages/embed"));

function App() {
  return (
    <div className="relative min-h-screen">
      <Router>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/police">
              <Police />
            </Route>
            <Route path="/government">
              <Government />
            </Route>
            <Route path="/embed">
              <EmbedRoute />
            </Route>
            <Route path="/">
              <Health />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
