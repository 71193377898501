import React from "react";

export default function Footer() {
  return (
    <footer className="flex justify-center text-gray-500 pb-6">
      <div className="container">
        <hr className="h-px mt-6 bg-gray-300 border-none" />
        <div className="flex flex-col items-center justify-between mt-6 md:flex-row">
          <div>
            <a
              href="https://ikon.mn"
              target="_blank"
              rel="noreferrer"
              className="text-xl font-bold"
            >
              <img
                style={{ height: 50 }}
                src="https://content.ikon.mn/ikon/images/ikon@2x.png"
                alt="ikon.mn logo"
              />
            </a>
          </div>
          <div className="flex mt-4 md:m-0">
            <div className="-mx-4">&copy; 2020 ikon.mn</div>
          </div>
          <div className="flex mt-4 md:m-0">
            <div className="-mx-4">
              Эх сурвалж:
              <a
                href="https://covid19.mohs.mn/"
                className="px-4 text-sm text-blue-500"
              >
                https://covid19.mohs.mn/
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
