export const reducer = (p, u) => ({ ...p, ...u });
export const getCachedData = (key, default_value) => {
  const cdata = localStorage.getItem(key);
  if (cdata) {
    try {
      return JSON.parse(cdata);
    } catch (ex) {
      return default_value;
    }
  } else {
    return default_value;
  }
};
export const getTodayDate = (sep) => {
  const offset = new Date().getTimezoneOffset();
  const yourDate = new Date();
  const yourDate1 = new Date(yourDate.getTime() - offset * 60 * 1000);
  let formated = yourDate1.toISOString().split("T")[0];
  if (sep) {
    formated = formated.replaceAll("-", sep);
  }
  return formated;
};
