import { memo } from "react";

const Vaccination = ({ data }) => {
  // const sum = data.vaccines.reduce((sum, item) => (sum = sum + item.count), 0);
  return (
    <div className="shadow-sm rounded bg-gradient-to-br from-green-50 via-green-100 to-green-200 px-2 pt-1 pb-2">
      <div className="font-sans font-bold text-sm text-center  text-green-700">
        Вакцинжуулалт
      </div>
      <div className="text-center text-xs mb-2 text-green-800">
        {data.started} - {data.updated}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="text-center">
          <div className="font-bold">
            <span className="text-green-600 text-base">I тун: </span>
            <span className="text-green-600 text-base">
              /
              {((data.progress * 100) / data.target)
                .toFixed(1)
                .toLocaleString()}
              %/
            </span>
          </div>
          <div className="font-sans font-bold text-2xl text-center text-green-600 pt-0">
            {data.progress.toLocaleString()}{" "}
          </div>
          <div className="mt-2 font-sans font-bold text-sm text-center  text-yellow-500">
            +{data.age} бүх иргэн /{data.targetyear}/
          </div>
          <div className="font-sans font-bold text-xl text-center text-yellow-500 pt-1">
            {data.target.toLocaleString()}
          </div>
        </div>
        <div className="text-center">
          <div className="font-bold">
            <span className="text-green-600 text-base">II тун: </span>
            <span className="text-green-600 text-base">
              /
              {((data.completed * 100) / data.target)
                .toFixed(1)
                .toLocaleString()}
              %/
            </span>
          </div>
          <div className="font-sans font-bold text-2xl text-center text-green-800 pt-0">
            {data.completed.toLocaleString()}{" "}
          </div>
          <div className="mt-2 font-sans font-bold text-sm text-center  text-red-500">
            III тун /
            {((data.d3 * 100) / data.target).toFixed(1).toLocaleString()}
            %/
          </div>
          <div className="font-sans font-bold text-2xl text-center text-red-500 pt-1">
            {data.d3.toLocaleString()}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Vaccination, () => true);
