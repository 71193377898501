import { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ChartTest = ({ data }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const total = {
      name: "Нийт тест",
      type: "area",
      data: [],
    };
    const daily = {
      name: "Өдөр бүр",
      type: "line",
      data: [],
    };

    data.forEach((r) => {
      total.data.push([r.ct, r.tt]);
      daily.data.push([r.ct, r.nt]);
    });

    setSeries([total, daily]);
  }, [data]);

  useEffect(() => {
    return () => {
      setSeries([]);
    };
  }, []);

  const options = {
    colors: ["rgba(59, 130, 246)", "rgba(30, 58, 138)"],
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    markers: {
      size: 0,
    },
    yaxis: [
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "rgba(59, 130, 246)",
        },
        labels: {
          style: {
            colors: "rgba(59, 130, 246)",
          },
        },
        title: {
          text: "Нийт тест",
          style: {
            color: "rgba(59, 130, 246)",
          },
        },
      },
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "rgba(30, 58, 138)",
        },
        labels: {
          style: {
            colors: "rgba(30, 58, 138)",
          },
        },
        title: {
          text: "Өдөр тус бүр",
          style: {
            color: "rgba(30, 58, 138)",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "yyyy/MM",
          day: "MM/dd",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {
      x: {
        format: "yy/MM/dd",
      },
    },
    legend: {
      height: 30,
    },
    annotations: {
      xaxis: [
        {
          x: new Date("14 Dec 2020").getTime(),
          strokeDashArray: 0,
          borderColor: "#ef5675dd",
          label: {
            offsetY: 10,
            textAnchor: "middle",
            borderColor: "#ef5675dd",
            style: {
              color: "#fff",
              background: "#ef5675dd",
            },
            text: "Xөл хорио дуусав",
          },
        },
      ],
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height="350"
    />
  );
};

export default memo(ChartTest, () => true);
