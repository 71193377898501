import React, { useEffect, Fragment, memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { scaleLinear /* scaleSequential */ } from "d3-scale";
import { PatternLines } from "@visx/pattern";
// import { interpolateMagma } from "d3-scale-chromatic";
import { TinyColor } from "@ctrl/tinycolor";
import { FaMoon, FaPlaneArrival } from "react-icons/fa";
import { MdLocalHospital } from "react-icons/md";
import { RiHomeHeartFill } from "react-icons/ri";

const geoUrl = "/topo-mongolia.json";

const MapCovid = ({
  tooltipRebuild,
  onClickMap,
  data,
  min = 0,
  max = 30000,
}) => {
  const getColorScale = () => {
    const currentScale = scaleLinear;
    const mapScale = currentScale()
      .domain([min, max + 20])
      .clamp(true);
    // const colorConvert = (x) => 0.95 - x * 0.95;
    // const colorScale = scaleSequential((d) => {
    //   const color = new TinyColor(interpolateMagma(colorConvert(mapScale(d))));
    //   return color.toRgbString();
    // });

    const colorScale = scaleLinear()
      .domain([min, max / 2])
      .range(["#ffedea", "#cd104a"]);

    return { colorScale, mapScale };
  };

  const getStrokeColor = (counts) => {
    const tinyColor = new TinyColor(colorScale(counts));

    return tinyColor.isDark()
      ? colorScale(mapScale.invert(mapScale(counts) - 0.6))
      : colorScale(mapScale.invert(mapScale(counts) + 0.3));
  };

  const { colorScale, mapScale } = getColorScale();

  useEffect(() => {
    // console.log("com did update");
    setTimeout(() => {
      tooltipRebuild();
    }, 1000);
  });

  // console.log(data);

  return (
    <>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          scale: 1700,
          center: [parseFloat(103.9), parseFloat(47.17)],
          parallels: [0, 0],
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo, i) => {
              // console.log(geo.properties.HASC_1);
              const cur = data.find((s) => s.mapid === geo.properties.HASC_1);
              const {
                total: counts = 0,
                name,
                id,
                new: new_case,
                co,
              } = cur || {};
              const strokeColor =
                counts === 0 ? "#dadada" : getStrokeColor(counts);
              return (
                <Fragment key={`f-${geo.rsmKey}`}>
                  <Geography
                    onClick={() => onClickMap(id)}
                    key={geo.rsmKey}
                    data-html={true}
                    data-tip={
                      counts > 0
                        ? `<span style="font-size: 10px;font-weight: bold;">${co}</span><br>${name}: <b style="color:red;">+${new_case.toLocaleString()}</b> / <b>${counts.toLocaleString()}</b>`
                        : ""
                    }
                    geography={geo}
                    style={{
                      default: {
                        fill:
                          counts === 0
                            ? `url("#itemmove-${i}") ${"#ddd"}`
                            : colorScale(counts),
                        stroke: counts > 0 ? "#000" : "#333",
                        strokeWidth: 1,
                        outline: "none",
                      },
                      hover: {
                        fill: `url("#itemmove-${i}") ${"#ddd"}`,
                        stroke: "#000",
                        strokeWidth: 2,
                        outline: "none",
                        cursor: counts > 0 ? "pointer" : "default",
                      },
                      pressed: {
                        fill: `url("#itemmove-${i}") ${"#ddd"}`,
                        stroke: "#000",
                        strokeWidth: 2,
                        outline: "none",
                        cursor: counts > 0 ? "pointer" : "default",
                      },
                    }}
                  />
                  <PatternLines
                    id={`itemmove-${i}`}
                    height={8}
                    width={counts > 0 ? 8 : 12}
                    stroke={strokeColor}
                    strokeWidth={1}
                    background={counts !== 0 ? colorScale(counts) : "#fff5"}
                    orientation={["diagonalRightToLeft"]}
                  />
                </Fragment>
              );
            })
          }
        </Geographies>
        {data
          .filter((f) => f.ctype !== "local")
          .map((m) => {
            return (
              <Fragment key={m.id}>
                {m.mapid === "hospital" && (
                  <Marker key={m.mapid} coordinates={[115.86, 43.83934]}>
                    <MdLocalHospital
                      onClick={() => onClickMap(m.id)}
                      className="map-marker"
                      data-tip={m.name}
                      size={80}
                      color={"#ddd"}
                      style={{
                        stroke: "#000",
                      }}
                    />
                  </Marker>
                )}
                {m.mapid === "import_saran" && (
                  <Marker key={m.mapid} coordinates={[88.883822, 43.83934]}>
                    <FaMoon
                      onClick={() => onClickMap(m.id)}
                      className="map-marker"
                      data-tip={m.name}
                      size={15}
                      color={"#ddd"}
                      style={{
                        stroke: "#000",
                      }}
                    />
                    <FaPlaneArrival
                      onClick={() => onClickMap(m.id)}
                      className="map-marker"
                      data-tip={m.name}
                      size={100}
                      color={"#ddd"}
                      style={{
                        stroke: "#000",
                      }}
                    />
                  </Marker>
                )}
                {m.mapid === "mongolia" && (
                  <Marker key={m.mapid} coordinates={[88.089612, 54.850513]}>
                    <svg
                      className="map-marker"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4800 2400"
                      onClick={() => onClickMap(m.id)}
                    >
                      <g fill="#f9cf02" transform="scale(0.15)">
                        <g transform="scale(5)">
                          <rect width="960" height="480" fill="#c4272f" />
                          <rect
                            x="320"
                            width="320"
                            height="480"
                            fill="#015197"
                          />
                          <circle cx="160" cy="164" r="44" />
                          <circle cx="160" cy="144" r="48" fill="#c4272f" />
                          <circle cx="160" cy="152" r="32" />
                          <path d="M140,92a20,20 0 0,0 40,0c0-8-5-9-5-12 0-3,3-7-3-12 3,5-2,6-2,11 0,5,2,5,2,9a4,4 0 0,1-8,0c0-4,4-8,4-14 0-6-1-8-4-13-3-5-8-9-4-13-6,2-3,10-3,15 0,5-4,8-4,14 0,6,3,7,3,11a4,4 0 0,1-8,0c0-4,2-4,2-9 0-5-5-6-2-11-6,5-3,9-3,12 0,3-5,4-5,12Z" />
                          <path d="M72,216V408H112V216ZM208,216V408H248V216ZM120,248V264H200V248ZM120,360V376H200V360ZM120,216H200L160,240ZM120,384H200L160,408Z" />
                        </g>
                        <g
                          transform="scale(1)"
                          stroke="#c4272f"
                          strokeWidth="24"
                        >
                          <circle cx="800" cy="1560" r="212" />
                          <path
                            fill="none"
                            d="M800,1348A106,106 0 0,1 800,1560A106,106 0 0,0 800,1772"
                          />
                        </g>
                        <g fill="#c4272f">
                          <circle cx="800" cy="1454" r="40" />
                          <circle cx="800" cy="1666" r="40" />
                        </g>
                      </g>
                    </svg>
                  </Marker>
                )}
                {m.mapid === "home" && (
                  <Marker key="bla" coordinates={[115.86, 53.179343]}>
                    <RiHomeHeartFill
                      onClick={() => onClickMap(m.id)}
                      className="map-marker"
                      data-tip={m.name}
                      size={80}
                      color={"#ddd"}
                      style={{
                        stroke: "#000",
                      }}
                    />
                  </Marker>
                )}
              </Fragment>
            );
          })}
      </ComposableMap>
    </>
  );
};

export default memo(MapCovid, () => true);
