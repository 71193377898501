import { memo } from "react";
import D3Sparkline from "./D3Sparkline";

const TestCard = ({ _, data }) => {
  return (
    <div className="shadow-sm rounded bg-gradient-to-tr from-gray-50 via-gray-100 to-grey-200 pt-4">
      <div className="font-sans font-bold text-sm text-center text-blue-900 pb-4">
        PCR Шинжилгээ
      </div>
      <div
        className="font-sans font-bold text-xl text-center text-blue-700"
        data-tip={data.lastupdate}
      >
        &nbsp;+{data.new.toLocaleString()}
      </div>
      <div
        className="font-sans font-bold text-2xl md:text-xl lg:text-2xl text-center text-blue-900"
        data-tip="2020.11.10-аас хойш "
      >
        {data.ttl.toLocaleString()}
      </div>

      <D3Sparkline data={data.trend || []} color={"rgba(30, 58, 138, 0.8)"} />
    </div>
  );
};

export default memo(TestCard, () => true);
