import React from "react";
import DropdownMenu from "../DropdownMenu";
import { useHistory, useLocation } from "react-router-dom";

const menus = {
  "/": {
    id: "/",
    text: "COVID-19",
  },
  "/police": {
    id: "/police",
    text: "ЦЕГ",
  },
  list: ["/", "/police"],
};

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const current = `/${pathname.split("/")[1]}`;

  return (
    <div className="flex justify-center text-gray-400">
      <div
        className="fixed flex bg-gray-100 justify-center w-full"
        style={{
          zIndex: 999999999999999,
          boxShadow:
            "0 2px 2px 0 rgba(0,0,0,0.04), 0 3px 1px -2px rgba(0,0,0,0.1), 0 1px 5px 0 rgba(0,0,0,0.06)",
        }}
      >
        <div className="container py-3 mx-4 text-gray-600 text-md">
          <div className="flex flex-row space-x-8">
            <a href="https://ikon.mn">
              <img
                className="self-end"
                alt="Зураг"
                src="https://content.ikon.mn/lr_clogo@2x.png"
                style={{ height: 22 }}
              />
            </a>
            <DropdownMenu
              current={current}
              menus={menus}
              onChange={(value) => {
                history.push(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
