import { memo } from "react";
import D3Sparkline from "./D3Sparkline";

const CardDeath = ({ data, updated }) => {
  return (
    <div className="shadow-sm rounded bg-gradient-to-b from-gray-100 via-white to-gray-400 pt-4">
      <div className="font-sans font-bold text-sm text-center text-gray-700 pb-4">
        Нас барсан
      </div>
      <div
        className="font-sans font-bold text-xl text-center text-gray-500"
        data-tip={updated}
      >
        &nbsp;{data.new > 0 && <span>+{data.new}</span>}
      </div>
      <div className="font-sans font-bold text-2xl md:text-2xl text-center text-gray-700">
        <span data-tip={`Ковид-19 хүндрэлээр: ${data.ttl.toLocaleString()}`}>
          {data.ttl.toLocaleString()}
        </span>
        &nbsp;/&nbsp;
        <span
          data-tip={`Бусад шалтгаант: ${(
            data.other_death || 0
          ).toLocaleString()}`}
        >
          {(data.other_death || 0).toLocaleString()}
        </span>
      </div>
      {data.ttl > 0 && (
        <D3Sparkline data={data.trend || []} color="rgba(55, 65, 81, 0.8)" />
      )}
      {data.ttl === 0 && (
        <div data-tip="Хичээгээрэй 👩🏻‍⚕️👨🏻‍⚕️😘" className="text-center text-6xl">
          🤞🏻
        </div>
      )}
    </div>
  );
};

export default memo(CardDeath, () => true);
