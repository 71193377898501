import { memo } from "react";
import D3Sparkline from "./D3Sparkline";

const ActiveCard = ({ data }) => {
  return (
    <div className="shadow-sm rounded bg-gradient-to-tl from-white via-purple-100 to-indigo-200 pt-4">
      <div className="font-sans font-bold text-sm text-center text-indigo-700 pb-4">
        Эмчлэгдэж буй
      </div>
      <div className="font-sans  text-xl text-center text-indigo-400">
        &nbsp;
      </div>
      <div className="font-sans font-bold text-2xl md:text-2xl text-center text-indigo-700">
        {data.ttl.toLocaleString()}
      </div>
      <D3Sparkline data={data.trend || []} color="rgba(67, 56, 202, 0.8)" />
    </div>
  );
};

export default memo(ActiveCard, () => true);
