import Footer from "./Footer";
import Header from "./Header";

const MainLayout = ({ children }) => {
  return (
    <div className="flex flex-col h-full bg-white text-gray-900">
      <Header />
      <div className="pt-16">
        <div className="container" style={{ margin: "auto" }}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
