import React from "react";

export default function Tab({ current, tabs, onChange }) {
  return (
    <div className="flex flex-row justify-center mb-2">
      <div className="bg-gray-200 text-xs text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
        {tabs?.map((t, i) => (
          <button
            className={`${
              current === t.id ? "active " : ""
            }inline-flex items-center focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2`}
            key={i}
            onClick={() => onChange(t.id)}
          >
            <span>{t.text}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
