import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import MapCovid from "../components/charts/MapCovid";
import CardConfirmed from "../components/charts/CardConfirmed";
import CardActive from "../components/charts/CardActive";
import CardRecovered from "../components/charts/CardRecovered";
import CardTest from "../components/charts/CardTest";
import DropdownMenu from "../components/DropdownMenu";
import { getCachedData, getTodayDate, reducer } from "../utils";
import { SiOpenstreetmap } from "react-icons/si";
import ChartConfirmed from "../components/charts/ChartConfirmed";
import ChartDaily from "../components/charts/ChartDaily";
import ChartNewDaily from "../components/charts/ChartNewDaily";
import Tab from "../components/Tab";
import CardLocal from "../components/charts/CardLocal";
import CardDeath from "../components/charts/CardDeath";
import ClusterTable from "../components/ClusterTable";
// import ChartCondition from "../components/charts/ChartCondition";
import ChartTest from "../components/charts/ChartTest";
// import ChartLocalVsImport from "../components/charts/ChartLocalVsImport";
import MainLayout from "../components/layouts/MainLayout";
import Vaccines from "../components/vaccines/Vaccines";
import Vaccination from "../components/vaccines/Vaccination";

const CovidNews = lazy(() => import("../components/CovidNews"));

const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  &:after {
    border-top-color: white !important;
  }
  opacity: 1 !important;
  padding: 4px 6px !important;
`;

const ranges = [
  {
    id: -30,
    text: "1 сар",
  },
  {
    id: -90,
    text: "3 сар",
  },
  {
    id: -182,
    text: "6 сар",
  },
  {
    id: 0,
    text: "Бүгд",
  },
];

const acharts = [
  {
    id: "test",
    text: "PCR тест",
  },
  {
    id: "condition",
    text: "Биеийн байдал",
  },
];

const ctypes = [
  {
    id: "daily",
    text: "Өдөр",
  },
  {
    id: "newdaily",
    text: "Батлагдсан vs. Эдгэрсэн",
  },
  {
    id: "total",
    text: "Нийт",
  },
];

export default function Health(props) {
  const initState = {
    loading: false,
    ctype: "daily",
    cluster: "1",
    chart: "test",
    range: -90,
  };
  const chartRef = useRef(null);
  const jumpTo = () => chartRef.current.scrollIntoView();
  const [state, setState] = useReducer(reducer, initState);
  const { data, loading, cluster, range, chart, ctype, vacData } = state;

  const fetchData = async () => {
    let data = getCachedData("data", {});
    let chartdata = getCachedData("chartdata", {});
    let tests = getCachedData("tests", []);
    const timeDiff = data.updated !== getTodayDate() ? 300000 : 1800000;
    if (!chartdata["1"] || Date.now() - (data.lastRequest || 0) > timeDiff) {
      setState({ loading: true });
      const cdate =
        ((chartdata["1"] || []).slice(-1)[0] || {}).co || "2020-01-01";
      const tdate = ((tests || []).slice(-1)[0] || {}).co || "2020-01-01";
      const response = await fetch(
        `https://ikon.mn/api/covid_reduced?tdate=${tdate}&cdate=${cdate}`
      ); //
      let rawdata = await response.json();
      const purge = (data.vaccination || {}).purge;
      data = rawdata.info;
      if (data.vaccination.purge !== purge) {
        chartdata = {};
        tests = [];
        localStorage.clear();
        const response = await fetch(
          `https://ikon.mn/api/covid_reduced?tdate=2020-01-01&cdate=2020-01-01`
        ); //
        rawdata = await response.json();
        data = rawdata.info;
      }
      data.lastRequest = Date.now();
      Object.keys(rawdata.chartdata).forEach((key) => {
        const cluster_data = chartdata[`${key}`] || [];
        const le = cluster_data.slice(-1)[0];
        const remote_data = rawdata.chartdata[key];
        if (
          remote_data.length > 0 &&
          (!le || (le && remote_data[0].co > le.co))
        ) {
          chartdata[`${key}`] = cluster_data.concat(remote_data);
        }
      });
      tests = tests.concat(rawdata.tests);
      try {
        localStorage.setItem("chartdata", JSON.stringify(chartdata));
        localStorage.setItem("tests", JSON.stringify(tests));
        localStorage.setItem("data", JSON.stringify(data));
      } catch (ex) {
        localStorage.clear();
      }
    }

    data.chartdata = chartdata;
    data.tests = tests;

    const tc = [],
      tr = [],
      ta = [],
      td = [],
      tt = [],
      tl = [];
    const last14 = data.chartdata["1"].slice(-14);
    last14.forEach((r) => {
      tc.push([r.ct, r.nc]);
      tr.push([r.ct, r.nr]);
      ta.push([r.ct, r.ta]);
      td.push([r.ct, r.nd]);
    });
    const lastlocal = data.chartdata["12"].slice(-14);
    lastlocal.forEach((r) => {
      tl.push([r.ct, r.tc]);
    });
    const last14test = data.tests.slice(-14);
    last14test.forEach((r) => {
      tt.push([r.ct, r.nt]);
    });
    data.confirmed.trend = tc;
    data.recovered.trend = tr;
    data.active.trend = ta;
    data.death.trend = td;
    data.death.other_death = data.clusters[0].other_death;
    data.dotood.trend = tl;
    data.pcr.trend = tt;
    data.pcr.lastupdate = last14test[last14test.length - 1].co;
    const clusterTypes = {
      list: data.clusters
        .filter((c) => c.ctype !== "dsa")
        .map((c) => `${c.id}`),
    };
    data.clusters.forEach((c) => {
      clusterTypes[`${c.id}`] = {
        id: `${c.id}`,
        text: c.name,
        data: c,
      };
    });
    data.clusterTypes = clusterTypes;

    // const vacRes = await fetch(`https://ikon.mn/api/json/vaccine`);
    // const vacData = await vacRes.json();

    setState({ vacData: data.vaccination, data: data, loading: false });
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const chart_data = useMemo(
    () => data?.chartdata[cluster].slice(range),
    [data, cluster, range]
  );

  const mapOnClick = (mapId) => {
    if (mapId)
      setState({
        cluster: `${mapId}`,
      });
  };

  return (
    <MainLayout>
      {loading && (
        <div style={{ height: "100vh", padding: "4px 8px" }}>
          Уншиж байна ...
        </div>
      )}
      {data && (
        <div className="pl-2 pr-2 sm:pl-0 sm:pr-0">
          <div className="p-8 text-gray-500">
            Эрүүл Мэндийн Яамны албан ёсны хуудас болон сайтаар 2022 оны
            дөрөвдүгээр сарын 29-нөөс эхлэн КОВИД-19 халдварын нөхцөл байдлын
            хоногийн тоон үзүүлэлтүүдийг дэлгэрэнгүй мэдээлэхээ больсон тус
            COVID-19 хуудас дахь доорх мэдээллүүд шинэчлэгдэхгүй болж байна.
          </div>
          <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
            <CardConfirmed data={data.confirmed} updated={data.updated} />
            <CardActive data={data.active} updated={data.updated} />
            <CardRecovered data={data.recovered} updated={data.updated} />
            <CardLocal data={data.dotood} updated={data.updated} />
            <CardTest data={data.pcr} updated={data.updated} />
            <CardDeath data={data.death} updated={data.updated} />
          </div>
          <div className="grid md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-4">
            <div>
              <div>
                <div className="flex flex-row justify-center">
                  <div
                    className="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex px-4 py-1"
                    style={{
                      position: "relative",
                      zIndex: 9999999,
                      padding: "6px 12px",
                    }}
                  >
                    <DropdownMenu
                      icon={
                        <SiOpenstreetmap
                          className="inline-block mr-1"
                          size="18"
                        />
                      }
                      current={cluster}
                      menus={data.clusterTypes}
                      onChange={(cluster) => setState({ cluster })}
                    />
                  </div>
                </div>
              </div>
              <div className="aspect-w-16 aspect-h-10">
                <MapCovid
                  {...props}
                  tooltipRebuild={() => ReactTooltip.rebuild()}
                  data={data.mapdata}
                  min={data.local.min}
                  max={data.local.max}
                  onClickMap={mapOnClick}
                />
              </div>
              <div className="text-center text-xs italic text-gray-600">
                Газрын зураг дээр дарж аймаг бүрээр нь харах боломжтой
              </div>
            </div>
            <div className="" ref={chartRef}>
              <Tab
                current={range}
                tabs={ranges}
                onChange={(range) => setState({ range })}
              />
              <Tab
                current={ctype}
                tabs={ctypes}
                onChange={(ctype) => setState({ ctype })}
              />
              <div className="">
                {ctype === "daily" && (
                  <ChartDaily
                    data={chart_data}
                    name={data.clusterTypes[cluster].text}
                  />
                )}
                {ctype === "total" && (
                  <ChartConfirmed
                    data={chart_data}
                    name={data.clusterTypes[cluster].text}
                  />
                )}
                {ctype === "newdaily" && (
                  <ChartNewDaily
                    data={chart_data}
                    name={data.clusterTypes[cluster].text}
                  />
                )}
              </div>
            </div>
          </div>
          <h2 className="font-bold text-center pt-6 pb-2 text-xl uppercase text-gray-700">
            Вакцинжуулалт
          </h2>
          {vacData && (
            <div className="mx-auto p-1">
              <div className="grid grid-cols1 md:grid-cols-2 gap-3">
                <Vaccines data={vacData} />
                <Vaccination data={vacData} />
              </div>
            </div>
          )}
          <iframe
            src="https://flo.uri.sh/visualisation/8054690/embed"
            title="Interactive or visual content"
            className="flourish-embed-iframe"
            frameBorder="0"
            scrolling="no"
            style={{ width: "100%", height: "600px" }}
            sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
          ></iframe>
          <ClusterTable
            clusters={data.clusters.filter((f) => f.ctype !== "ds")}
            clusterTypes={data.clusterTypes}
            chartdata={data.chartdata}
            updated={data.updated}
            onChange={(mapId) => {
              setState({
                cluster: `${mapId}`,
              });
              jumpTo();
            }}
          />
          <div className="py-6" style={{ maxWidth: 1000, margin: "auto" }}>
            <Tab
              current={chart}
              tabs={acharts}
              onChange={(chart) => setState({ chart })}
            />
            {chart === "test" && <ChartTest data={data.tests} />}

            {chart === "condition" && (
              <div>
                <iframe
                  src="https://flo.uri.sh/visualisation/6752510/embed"
                  title="Interactive or visual content"
                  className="flourish-embed-iframe"
                  frameBorder="0"
                  scrolling="no"
                  width="100%"
                  height="550"
                  sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
                ></iframe>
              </div>
            )}
          </div>
          <div className="aspect-w-5 aspect-h-3">
            <iframe
              src="https://flo.uri.sh/visualisation/6752423/embed"
              title="Interactive or visual content"
              frameBorder="0"
              scrolling="no"
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            ></iframe>
          </div>

          <Suspense fallback={<div> Уншиж байна</div>}>
            <CovidNews />
          </Suspense>
          <StyledReactTooltip html={false} type="light" />
        </div>
      )}
    </MainLayout>
  );
}
