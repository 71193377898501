import { memo } from "react";

const Vaccines = ({ data }) => {
  const total = data.vaccine_target;
  const sum = data.vaccines.reduce((sum, item) => (sum = sum + item.count), 0);
  return (
    <div className="shadow-sm rounded bg-gradient-to-tl from-white via-purple-100 to-indigo-200 px-2 py-1">
      <div className="font-sans font-bold text-sm text-indigo-700">
        Вакцины нөөц ({data.vupdated})
      </div>
      {data.vaccines.map((v) => (
        <div
          key={v.name}
          className="flex"
          style={{ borderTop: "1px solid rgba(67, 56, 202, 0.2)" }}
        >
          <div className="font-sans text-xs text-indigo-700 py-1 flex-grow">
            &nbsp;&bull;&nbsp;{v.name}
          </div>
          <div
            className="font-sans text-xs text-indigo-700 py-1 text-right"
            style={{ width: 80 }}
          >
            {v.count.toLocaleString()}
          </div>
        </div>
      ))}
      <div
        className="flex"
        style={{ borderTop: "1px solid rgba(67, 56, 202, 0.2)" }}
      >
        <div className="font-sans text-xs text-indigo-700 py-1 flex-grow">
          Нийт бүрдсэн (
          <strong>{((sum * 100) / total).toLocaleString()}%</strong>)
        </div>
        <div
          className="font-sans text-xs text-indigo-700 py-1 text-right"
          style={{ width: 80 }}
        >
          <strong>{sum.toLocaleString()}</strong>
        </div>
      </div>
      <div
        className="flex"
        style={{ borderTop: "1px solid rgba(67, 56, 202, 0.2)" }}
      >
        <div className="font-sans text-xs text-indigo-700 py-1 flex-grow">
          Шаардлагатай тун
        </div>
        <div
          className="font-sans text-xs text-indigo-700 py-1 text-right"
          style={{ width: 80 }}
        >
          {total.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

export default memo(Vaccines, () => true);
