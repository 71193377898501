import { memo } from "react";
import D3Sparkline from "./D3Sparkline";

const CardLocal = ({ data, updated }) => {
  return (
    <div className="shadow-sm rounded bg-gradient-to-br from-blue-100 via-pink-100 to-yellow-100 pt-4">
      <div className="font-sans font-bold text-sm text-center text-yellow-700 pb-4">
        Дотоод
      </div>
      <div
        className="font-sans font-bold text-xl text-center text-yellow-500"
        data-tip={updated}
      >
        &nbsp;<span>+{data.new}</span>
      </div>
      <div
        className="font-sans font-bold text-2xl md:text-2xl text-center text-yellow-700"
        data-html="true"
      >
        {data.ttl.toLocaleString()}
      </div>
      <D3Sparkline data={data.trend || []} color="rgba(180, 83, 9, 0.8)" />
    </div>
  );
};

export default memo(CardLocal, () => true);
