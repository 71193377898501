import React, { Fragment, memo, useEffect } from "react";
import { AiOutlineAreaChart } from "react-icons/ai";

const Badge = ({ type, children }) => {
  if (type === "danger")
    return (
      <div className="rounded-lg bg-red-100 px-2 inline-block text-red-600">
        {children}
      </div>
    );
  if (type === "success")
    return (
      <div className="rounded-lg bg-green-100 px-2 inline-block text-green-600">
        {children}
      </div>
    );
  if (type === "neutral")
    return (
      <div className="rounded-lg bg-gray-100 px-2 inline-block">{children}</div>
    );
  return (
    <div className="rounded-lg bg-purple-100 px-2 inline-block text-purple-600">
      {children}
    </div>
  );
};

const ClusterTable = ({
  clusters,
  chartdata,
  clusterTypes,
  onChange,
  updated,
}) => {
  useEffect(() => {
    // console.log("mount cluster table");
    return () => {
      // console.log("unmount cluster table");
    };
  }, []);

  // console.log("render cluster table");

  return (
    <div>
      <h2 className="font-bold text-center pt-6 pb-2 text-xl uppercase text-gray-700">
        Хүснэгтээр
      </h2>
      <div className="sm:hidden italic p-2 text-xs text-gray-500 text-center">
        Хүснэгтийг хажуу тийш гүйлгэж бүрэн хараарай
      </div>
      <div className="shadow rounded-lg overflow-x-auto pb-4">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Байршил
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider">
                {updated}
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Нийт
              </th>
              <th
                data-tip="Халдвар илрээгүй хоног"
                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider"
              >
                Илрээгүй
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Эдгэрэлт
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider">
                Эмчилгээнд
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 tracking-wider">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {clusters.map((r) => {
              const lastdays = chartdata[r.id].slice(-14);
              let days = 0;
              for (let i = chartdata[r.id].length - 1; i > -1; i--) {
                if (chartdata[r.id][i].nc > 0) {
                  break;
                } else {
                  days += 1;
                }
              }
              const last7 = lastdays[lastdays.length - 1];

              return (
                <Fragment key={r.id}>
                  <tr key={r.id}>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                      {r.name}
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <Badge type={last7.nc > 0 ? "danger" : "success"}>
                        +{last7.nc.toLocaleString()}
                      </Badge>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs font-bold text-center">
                      <Badge type="neutral">{last7.tc.toLocaleString()}</Badge>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <Badge type={days > 14 ? "success" : "neutral"}>
                        {days} хоног
                      </Badge>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <Badge type="success">
                        {((last7.tr / (last7.tc - r.nb)) * 100).toFixed(1)}%
                      </Badge>
                      &nbsp;
                      <Badge>
                        {last7.tr}/{last7.tc - r.nb}
                      </Badge>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <Badge type="danger">
                        {((last7.ta / (last7.tc - r.nb)) * 100).toFixed(1)}%
                      </Badge>
                      &nbsp;
                      <Badge type="neutral">
                        {last7.ta}/{last7.tc - r.nb}
                      </Badge>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-center">
                      <AiOutlineAreaChart
                        onClick={() => onChange(r.id)}
                        className="cursor-pointer text-purple-500"
                        size="24"
                      />
                    </td>
                  </tr>
                  {(r.subinfos?.child || []).map((si) => {
                    const dist = clusterTypes[`${si}`];
                    const dl17 = chartdata[`${si}`].slice(-14);
                    const dlast = dl17[dl17.length - 1];
                    return (
                      <tr key={si}>
                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{dist.data.name}
                        </td>
                        <td
                          colSpan=""
                          className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                        >
                          {dlast?.nc > 0 && dlast?.co === updated && (
                            <Badge type={dlast?.nc > 0 ? "danger" : "success"}>
                              +{dlast?.nc.toLocaleString()}
                            </Badge>
                          )}
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-center">
                          <Badge type="neutral">
                            {dlast?.tc.toLocaleString()}
                          </Badge>
                        </td>
                        <td
                          colSpan="3"
                          className="pl-10 py-2 border-b border-gray-200 bg-white text-xs"
                        ></td>
                        <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm text-center">
                          <AiOutlineAreaChart
                            onClick={() => onChange(si)}
                            className="cursor-pointer text-purple-500"
                            size="24"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(ClusterTable, () => true);
